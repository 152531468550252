
import { defineAsyncComponent } from "vue";
import { Vue, Options } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import debounce from "lodash/debounce";
import { OnProcessSttController } from "@/app/ui/controllers/OnProcessSttController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import { AccountController } from "@/app/ui/controllers/AccountController";
import Book from "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue";
import router from "@/app/ui/router";
import { GTMOnProcessSttEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/on-process-stt-events";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
const DetailRejectPopup = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/shipment/booking/detail-booking/detail-reject-popup.vue"
    )
});
import { FlagsPermissionOnProcessstt } from "@/feature-flags/flags-permission-on-process-stt";
import { ShipmentBooking } from "@/domain/entities/ShipmentBooking";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";

@Options({
  components: {
    DetailRejectPopup,
    OverlayPanel,
    Book,
    CustomDropdown
  }
})
export default class OnProcessSTT extends Vue {
  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }

  mounted() {
    this.refs = this.$refs;
    OnProcessSttController.initOnProcessSttList();
  }

  get isDetailAble() {
    return FlagsPermissionOnProcessstt.on_process_stt_detail_edit.isEnabled();
  }
  get isEditAble() {
    return FlagsPermissionOnProcessstt.on_process_stt_detail_edit.isEnabled();
  }
  isEditAbleBasedOnAccountType(lastStatus: string) {
    if (this.dataProfile.isConsoleAccount) {
      return lastStatus === "STI";
    }
    return false;
  }
  get isCancelAble() {
    return FlagsPermissionOnProcessstt.on_process_stt_detail_cancel.isEnabled();
  }

  onRequest() {
    OnProcessSttController.fetchOnProcessSttList();
  }

  get firstRequest() {
    return OnProcessSttController.firstRequest;
  }

  get shipmentBooking() {
    return OnProcessSttController.onProcessSttData.data.map(item => {
      return {
        ...item,
        customDeleteAction: this.isMigratingFromElexys
          ? false
          : this.isCancelAble &&
            this.isEditAbleBasedOnAccountType(item.sttLastStatusId)
      };
    });
  }

  //Filter Search
  onSearch(value = "") {
    OnProcessSttController.setSearch(value);
    OnProcessSttController.resetPage();
    OnProcessSttController.fetchOnProcessSttList();
  }
  get searchValue() {
    return OnProcessSttController.search;
  }
  clearSearch() {
    OnProcessSttController.setSearch("");
    OnProcessSttController.fetchOnProcessSttList();
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    OnProcessSttController.setFilter(event);
    OnProcessSttController.resetPage();
    this.onRequest();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    OnProcessSttController.resetFilter();
    this.onRequest();
  }, 500);
  get advancedFilterData() {
    return OnProcessSttController.filter;
  }

  // Pagination Table
  get pagination() {
    return OnProcessSttController.onProcessSttData.pagination;
  }

  //Loading
  get isLoading() {
    return OnProcessSttController.isLoading;
  }

  //Error
  get isError() {
    return OnProcessSttController.isError;
  }

  //Error Cause
  get errorCause() {
    return OnProcessSttController.isErrorCause;
  }

  //misc
  refs: any = {};

  // Table
  columns = [
    {
      name: "No. STT",
      styleHead: "w-50 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentBooking) => {
        return `
            <div class="flex flex-row items-center">
              <div class='text-left mr-1'>${item.sttNo}</div>
              ${
                item.sttIsCod
                  ? item.sttIsDfod
                    ? `<img src="${require("@/app/ui/assets/svg/dfod-badge.svg")}" />`
                    : `<img src="${require("@/app/ui/assets/svg/cod-badge.svg")}" />`
                  : ""
              }
            </div>
          `;
      }
    },
    {
      name: "No. shipment",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${item.sttShipmentId}</div>`;
      }
    },
    {
      name: "Alamat tujuan",
      styleHead: "w-64 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="overflow-ellipsis text-black-lp-300 line-clamp-2" title="${item.sttRecipientAddress}">${item.sttRecipientAddress}</div>`;
      }
    },
    {
      name: "Status terakhir",
      styleHead: "w-40 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300 flex">
                    <div class="rounded capitalize px-2 py-0 ${this.mapLastStatusColor(
                      item.sttLastStatusId
                    )}">
                        ${item.sttLastStatusId}
                    </div>
                  </div>`;
      },
      toolTip: (item: ShipmentBooking) => {
        if (item.sttLastStatusId === "CODREJ") {
          return `<div class='flex text-black-lp-300 text-12px max-w-xs'>${item.dexCodrejReason}</div>`;
        }
        if (item.sttLastStatusId === "DEX") {
          return `
              <div class="text-xs">
                <h5 class="text-gray-lp-300 font-semibold">Percobaan DEX ke ${item.totalSttDex}</h5>
                <p class="text-gray-lp-600">${item.dexCodrejReason}</p>
              </div>
            `;
        }
      }
    },
    //   Command due to enhancement on status adjustment 
    // {
    //   name: "Status adjustment",
    //   styleHead: "w-44 text-left whitespace-no-wrap",
    //   render: (item: ShipmentBooking) => {
    //     return `<div class="${this.mapAdjustmentStatusColor(
    //                 item.sttAssessmentStatus || ""
    //               )}">${this.mapAdjustmentStatusText(item.sttAssessmentStatus || "")}</div>`;
    //   }
    // },
    {
      name: "Jenis pengiriman",
      styleHead: "w-40 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttProductType}
                  </div>
                </div>`;
      }
    },
    {
      name: "Tanggal booking",
      styleHead: "w-64 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.sttCreatedAt
        )}</div>
                  <div class="capitalize text-gray-lp-500">${ellipsisString(
                    item.sttCreatedName,
                    30
                  )}</div>`;
      }
    },
    {
      name: "Tanggal diupdate",
      styleHead: "w-64 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.sttUpdatedAt
        )}</div>
                  <div class="capitalize text-gray-lp-500">${ellipsisString(
                    item.sttUpdateBy || "-",
                    30
                  )}</div>`;
      }
    },
    {
      name: "Dibooking untuk",
      styleHead: "w-64 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${item.sttBilledTo ||
          "-"}</div>`;
      }
    },
    {
      name: "Action",
      key: "actionable_column",
      styleHead: "w-40 text-center",
      showButton: () => {
        return true;
      }
    }
  ];

  get isEditable(): boolean {
    return this.isMigratingFromElexys || !this.isEditAble;
  }

  actionGroupOptions = (item: ShipmentBooking) => {
    const language = item.sttProductType === "INTERPACK" ? "en" : "id";
    const groupOptions = [
      {
        icon: "pencil-alt-disable",
        label: "Ubah Booking",
        clickFunction: () => this.goToEdit(item.sttId)
      },
      {
        icon: "printer",
        label: "Print Thermal Resi",
        clickFunction: () => this.print(item.sttId, "thermal", language)
      },
      {
        icon: "document-download-outline-grey",
        label: "Print Thermal Resi PDF",
        clickFunction: () => this.print(item.sttId, "thermal-pdf", language)
      },
      {
        icon: "document-download-outline-grey",
        label: "Print Basic/Save to PDF",
        clickFunction: () => this.print(item.sttId, "basic", language)
      }
    ];
    if (
      this.isEditable ||
      !this.isEditAbleBasedOnAccountType(item.sttLastStatusId)
    ) {
      return groupOptions.slice(1, 4);
    }
    return groupOptions;
  };

  async print(sttId: number, type = "", language = "id") {
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () => book.default.methods.printThermal(sttId, language),
      "thermal-pdf": () =>
        book.default.methods.printBasic(sttId, 0, language, false, true),
      basic: () => book.default.methods.printBasic(sttId, 1, language),
      "commercial-invoice": () =>
        book.default.methods.directPrintInterpack(sttId)
    };

    printType[type]();
  }

  goToDetail(item: ShipmentBooking) {
    GTMOnProcessSttEvent("on_process_stt_detail", {
      status: item.sttLastStatusId,
      sttNumber: item.sttNo
    });
    this.$router.push(`/shipment/on-process-stt/${item.sttId}`);
  }
  goToEdit(id: number) {
    router.push(`/shipment/on-process-stt/${id}/edit`);
  }

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get listStatus() {
    if (this.dataProfile.isConsoleAccount) {
      return [
        { name: "ALL", value: "ALL" },
        { name: "STI", value: "STI" },
        { name: "STI DEST", value: "STI-DEST" },
        { name: "BAGGING", value: "BAGGING" },
        { name: "TRANSIT", value: "TRANSIT" },
        { name: "MISROUTE", value: "MIS-ROUTE" },
        { name: "HANDOVER", value: "HND" },
        { name: "DEL", value: "DEL" },
        { name: "DEX", value: "DEX" },
        { name: "CARGO PLANE", value: "CARGO PLANE" },
        { name: "CARGO TRUCK", value: "CARGO TRUCK" },
        { name: "CARGO TRAIN", value: "CARGO TRAIN" },
        { name: "CARGO SHIP", value: "CARGO SHIP" },
        { name: "REJECTED", value: "REJECTED" },
        { name: "REROUTE", value: "REROUTE" },
        { name: "MISBOOKING", value: "MISBOOKING" },
        { name: "RTS", value: "RTS" },
        { name: "RTSHQ", value: "RTSHQ" },
        { name: "IN HUB", value: "IN-HUB" },
        { name: "OUT HUB", value: "OUT-HUB" }
      ];
    }

    if (this.dataProfile.isSubConsoleAccount) {
      return [
        { name: "STI-SC", value: "STI-SC" },
        { name: "STI DEST-SC", value: "STI DEST-SC" },
        { name: "BAGGING", value: "BAGGING" },
        { name: "TRANSIT", value: "TRANSIT" },
        { name: "MISROUTE", value: "MIS-ROUTE" },
        { name: "CARGO PLANE", value: "CARGO PLANE" },
        { name: "CARGO TRUCK", value: "CARGO TRUCK" },
        { name: "CARGO TRAIN", value: "CARGO TRAIN" },
        { name: "CARGO SHIP", value: "CARGO SHIP" },
        { name: "REJECTED", value: "REJECTED" },
        { name: "REROUTE", value: "REROUTE" },
        { name: "MISBOOKING", value: "MISBOOKING" },
        { name: "RTS", value: "RTS" },
        { name: "RTSHQ", value: "RTSHQ" }
      ];
    }

    if (this.dataProfile.isPosParentAccount) {
      return [{ name: "PUP", value: "PUP" }];
    }

    if (this.dataProfile.isPosChildAccount) {
      return [
        { name: "PUP", value: "PUP" },
        { name: "PUP-C", value: "PUP-C" }
      ];
    }

    return [{ name: "", value: "" }];
  }
  isOpenSelectStatus = false;

  get filterStatus() {
    return OnProcessSttController.status;
  }

  onOpenSelectStatus() {
    this.isOpenSelectStatus = true;
  }
  onCloseSelectStatus() {
    this.isOpenSelectStatus = false;
  }
  onSelectStatus(_name: string, value = "") {
    OnProcessSttController.setStatus(value);
    OnProcessSttController.resetPage();
    this.onRequest();
    this.onCloseSelectStatus();
  }

  // cancel booking
  isRejectPopup = false;
  sttNumberSelected = "";
  onOpenPopupCancelBooking(item: ShipmentBooking) {
    this.sttNumberSelected = item.sttNo;
    this.isRejectPopup = true;
  }

  renderCodType(data: ShipmentBooking) {
    const codType = this.renderDfod(data.sttIsCod, data.sttIsDfod);
    return codType
      ? ChipsStyles({ status: "gray", title: codType, square: true })
      : "-";
  }

  renderDfod(cod: boolean, dfod: boolean) {
    let label;
    if (cod && dfod) {
      label = "DFOD";
    } else if (cod && !dfod) {
      label = "COD";
    }

    return label;
  }

  // check last status red color
  checkLastStatusRedColor(status: string): boolean {
    return [
      "DEX",
      "SCRAP",
      "SHORTLAND",
      "MIS-ROUTE",
      "CODREJ",
      "MISSING",
      "DAMAGE",
      "NOT RECEIVE",
      "NOT RECEIVED",
      "MISBOOKING",
      "REJECTED"
    ].includes(status);
  }

  // check last status blue color
  checkLastStatusBlueColor(status: string): boolean {
    return [
      "CNX",
      "POD",
      "RTS",
      "CLAIM",
      "CI",
      "RTSHQ",
      "STT ADJUSTED POD",
      "REROUTE"
    ].includes(status);
  }

  // mapping last status color
  mapLastStatusColor(status: string): string {
    const uppercaseStatus = status.toUpperCase();
    if (this.checkLastStatusBlueColor(uppercaseStatus))
      return "bg-blue-lp-600 text-blue-lp-700";
    if (this.checkLastStatusRedColor(uppercaseStatus))
      return "bg-red-lp-1300 text-red-lp text-red-lp-510";
    return "bg-gray-lp-1400 text-gray-lp-300";
  }

  // mapping adjustment status color
  mapAdjustmentStatusColor(status: string): string {
    if (status === "approved")
      return "text-green-lp-400";
    if (status === "rejected")
      return "text-red-lp-500";
    if (status === "waiting")
      return "text-yellow-lp-500";
    return "";
  }

  // mapping adjustment status text
  mapAdjustmentStatusText(status: string): string {
    if (status === "approved")
      return "Diterima";
    if (status === "rejected")
      return "Ditolak";
    if (status === "waiting")
      return "Menunggu";
    return "";
  }

  //Filter assesstment status
  sttAssessmentStatus: any = {};
  get sttAssessmentStatusData() {
    return [
      { name: "Semua", value: "" },
      { name: "Menunggu", value: "waiting" },
      { name: "Ditolak", value: "rejected" },
      { name: "Diterima", value: "approved" }
    ];
  };

  onSelectSttAssessmentStatus(item: any) {
    this.sttAssessmentStatus = item.value
    OnProcessSttController.selectSttAssessmentStatusAction(item.value)
  };
}
